<template>
	<v-text-field
        hide-details dense filled
        :ref="name"
        :value="value"
        @input="$emit('changed', $event)"
        autocomplete="new-password"
    >
        <template v-slot:prepend-inner>
            <div class="small text-center black--text align-end">{{label + ':'}}</div>
        </template>

    </v-text-field>
</template>
<script>
export default {
  name: 'TextInputPf',
  props: ['label', 'name', 'value'],
}
</script>