<template>
    <v-dialog v-model="dialog" max-width="800px" persistent overlay-opacity="0.75">
      	<v-card class="elevation-0 grey darken-3">
			<v-toolbar color="blue-grey darken-3" dark dense elevation="0">
				<v-toolbar-title>{{ cfgTable.model_title }}:</v-toolbar-title>
				<v-text-field flat solo dense hide-details background-color="blue-grey darken-4" readonly :value="item.name" class="mx-3"
				></v-text-field>  
				<v-spacer></v-spacer>
				<v-btn disabled plain small>ID: {{ item.id }}</v-btn>
				<v-btn ripple icon><v-icon color="yellow darken-3">mdi-star</v-icon></v-btn>
			</v-toolbar>
	        <!--<v-card-title>
	        	<span class="headline">{{ schema.formname }}</span>
			    <v-spacer />
	        	<v-text-field hide-details disabled reverse label="ID" v-model="item.id"></v-text-field>
	        </v-card-title>-->
	        <v-card-text>
	        </v-card-text>
          		<v-container v-if="loading || !loading">
					<!--<v-row>
						<v-col sm="8">
	        				<v-text-field hide-details readonly rounded
							 v-model="schema.formname"></v-text-field>
						</v-col>
						<v-col>
	        				<v-text-field hide-details disabled reverse label="ID" v-model="item.id"></v-text-field>
						</v-col>
					</v-row>-->
					<v-row>
						<v-col v-for="(field, index) in cfgForms" :key="index" :cols="field.width">
							<component :is="inputComp[field.type]"
										:label="field.title"
										:format="field.format"
										:name="field.column_name"
										:value="item[field.column_name]"
										:options="field.opt"
										:reftable="field.lookuptable"
										:refcol="item[field.lookupcol]"
										:type="field.type"
										@changed="updateForm(field.column_name, field.lookupcol, $event)"
							>
							</component>
						</v-col>
					</v-row>
	        	</v-container>
	        <v-card-text>
	        </v-card-text>
        	<v-card-actions>
        		<v-btn text class="warning mx-0 mb-2" @click="onCancel()">Abbrechen</v-btn>
        		<v-btn v-if="!error" text class="success mx-1 mb-2" :loading="loading" :disabled="loading || !dirty"
				 @click="onSubmit()">Speichern</v-btn>
        		<v-btn v-else text class="error mx-1 mb-2" disabled>{{ error }}</v-btn>
			    <v-spacer />
        		<v-btn v-if="!error && newItem" text class="primary mx-0 mb-2" :loading="loading" :disabled="loading || !dirty"
				 @click="onSubmit('new')">Speichern und Neu</v-btn>
        	</v-card-actions>
      	</v-card>
		<v-dialog v-model="showmessage">
			<v-toolbar dark color="warning" dense>
				<v-toolbar-title class="white--text">{{ message.title }}</v-toolbar-title>
			</v-toolbar>
			<v-card tile>
				<v-card-text color="red" v-html="message.msg"/>
				<v-card-actions>
					<v-spacer/>
					<v-btn color="blue" text @click="onMessageClose()">Ok</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
        <v-overlay absolute :value="loading">
        	<v-progress-circular indeterminate size="64" width="7" color="cyan darken-4"></v-progress-circular>
        </v-overlay>
    </v-dialog>          
</template>
<script>
import NumberInput from "./components/NumberInput";
import DecimalInput from "./components/DecimalInput";
import ListSelect from "./components/ListSelect";
import TextInput from "./components/TextInput";
import TextAreaInput from "./components/TextAreaInput";
import TextInputPf from "./components/TextInputPf";
import LookupInput from "./components/LookupInput";
import DateInput from "./components/DateInput";
import KWDateInput from "./components/KWDateInput";
import NumberView from './components/NumberView'

export default {
	name: "GenericForm",
	components: { NumberInput, DecimalInput, ListSelect, TextInput, TextAreaInput, TextInputPf, LookupInput, DateInput, KWDateInput },
	props: {
		modulData: {
			type: Object,
			required: true,
			default: null,
		},
	},
	data: () => ({
		dialog: false,
		loading: false,
		dirty: false,
		newItem: false,
		newItemMore: false,
		error: '',
		message: {},
		showmessage: false,
		cfgTable: {},
		cfgForms: [],
		item: {},
		inputComp: {
			'default': 'TextInput', 'textarea': 'TextAreaInput', 'number': 'NumberInput', 'select': 'ListSelect', 'lookup': 'LookupInput', 'datepick': 'DateInput',
			'kw': 'KWDateInput', 'decimal': 'DecimalInput', 'calc': 'DecimalInput'
		},
	}),
	computed: {
	},
	methods: {
		updateForm(fieldName, fieldRefCol, newValue) {
			//console.log(fieldName, fieldRefCol, newValue)
			if(newValue && typeof(newValue) === 'object') {
				this.$set(this.item, fieldName, newValue.id)
				this.$set(this.item, fieldRefCol, newValue)
			} else {
				this.$set(this.item, fieldName, newValue)
			}
			this.dirty = true
			if(fieldRefCol) this.adoptFields(fieldRefCol)
			this.calcFields()
		},
		adoptFields(refCol) {
			this.cfgForms.forEach(i => {
				//console.log(i.adopt)
				//if(i.adopt) console.log(i.adopt.search(refCol + '.'))
				if(i.adopt && refCol && refCol != '' && i.adopt.search(refCol + '.') == 0) {
					let result = this.item[refCol][i.adopt.replace(refCol + '.', '')]
					if(result) this.item[i.column_name] = result
				}
			})
		},
		calcFields() {
			this.cfgForms.forEach(i => {
				if(i.type == 'calc' && i.calc != '') {
					let calc = i.calc
					Object.keys(this.item).forEach(e => {
						if(this.item[e]) calc = calc.replace(e, this.item[e])
					})
					let result = null
					try { result = eval(calc) } catch(error) {if (error instanceof ReferenceError) {} else {throw error}}
					if(result) this.item[i.column_name] = result.toFixed(2)
				}
			})
		},
		closeDialog(event) {
			this.loading = false
			this.dialog = false
			this.$store.commit('generic/resetItem')
			this.$emit(event)
		},
		msgError(errorMsg) {
			this.loading = false
			this.error = "Eingaben konnten nicht gespeichert werden"
			let self = this
			setTimeout(() => {
				self.error = ''
			}, 5000);
			this.message = '<code>' + JSON.stringify(errorMsg).substring(0, 200) + '</code>'
			this.showmessage = true
		},
		onSubmit(opt) {
			this.saveData(opt)
		},
		onCancel() {
			if(this.newItemMore) {
				this.closeDialog('submitted')
			} else {
				this.closeDialog('cancelled')
			}
		},
		onMessageClose() {
			if(this.message.status == 403) this.onCancel()
			this.message = {}
			this.showmessage = false			
		},
		async saveData(opt) {
			this.loading = true
			const param = {
				tablename: this.modulData.meta.subtable,
				data: this.item
			}
            try {
                await this.$store.dispatch('generic/saveItem', param)
				if(opt == 'new') {
					this.newItemMore = true
					this.createData()
				} else {
					this.closeDialog('submitted')
				}
        	} catch (error) {
                console.error(error)
				console.log(error.response)
				//console.log(error.response.data.message)
				//this.msgError(error.response.data)
                this.message.status = error.response.status
                this.message.data = error.response.data
                this.message.title = error.name
                this.message.msg = '<code>' + JSON.stringify(error.response.data).substring(0, 200) + '</code>'
                this.showmessage = true
            } finally {
                this.loading = false
            }
		},
		async editData(model) {
			this.loading = true
			const param = {
				tablename: this.modulData.meta.subtable,
				data: model
			}
            try {
                await this.$store.dispatch('generic/editItem', param)
				this.item = Object.assign({}, this.$store.getters['generic/getItem'])
 				this.dirty = false
				this.newItem = false
        	} catch (error) {
                console.error(error)
                this.message.status = error.response.status
                this.message.data = error.response.data
                this.message.title = error.name
                this.message.msg = '<code>' + JSON.stringify(error.message).substring(0, 200) + '</code>'
                this.showmessage = true
            } finally {
                this.loading = false
            }
		},
		async createData(model) {
            this.loading = true
			const param = {
				tablename: this.modulData.meta.subtable,
			}
            try {
                await this.$store.dispatch('generic/createItem', param)
				this.item = Object.assign({}, this.$store.getters['generic/getItem'])
				this.item = Object.assign(this.item, model)
 				this.dirty = true
				this.newItem = true
           } catch (error) {
                console.error(error)
                this.message.status = error.response.status
                this.message.data = error.response.data
                this.message.title = error.name
                this.message.msg = '<code>' + JSON.stringify(error.message).substring(0, 200) + '</code>'
                this.showmessage = true
            } finally {
                this.loading = false
            }
		},
		getCfgs() {
			const param = {
				table_name: this.modulData.meta.subtable,
				reftable: this.modulData.meta.tablename,
			}
			//console.log('getCfgs param:', param)
			//if(this.reftable == 'none') { param.reftable = this.tablename }
			this.cfgTable = this.$store.getters['core/getCfgTable'](param)
			this.cfgForms = this.$store.getters['core/getCfgForms'](param)
		},
	},
	beforeMount() {
		this.getCfgs()
		const model = this.modulData.meta.model
		if(model && model.id) {
			this.editData(model)
		} else {
			this.createData(model)
		}
		this.dialog = true
	},
	mounted() {
	},
	watch: {
	},
}  
</script>

<style>
.theme--dark.v-label {
    color: rgba(255, 255, 255, 0.3);
}
</style>
