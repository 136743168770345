<template><div>
        <v-text-field
            hide-details dense outlined readonly :clearable="!!lookupValue" clear-icon="mdi-close-circle-outline"
            class="lookup_cursor"
            :label="label"
            :ref="name"
            :value="lookupValue"
            :placeholder="label"
            autocomplete="new-password"
            :append-icon="'mdi-menu'"
            @click:append="openSelect()"
            @click:clear="onClear()"
            @click="openSelect()"
        >
            <template v-slot:append-outer v-if="!!lookupValue">
                <v-btn small icon color="grey"><v-icon>mdi-eye</v-icon></v-btn>
            </template>
        </v-text-field>
        <LookupList v-if="openLookupList" :label="label" :reftable="reftable" @cancel="onCancel" @submit="onSubmit($event)" />
    </div>
</template>
<script>
import LookupList from './LookupList'
export default {
    name: 'LookupInput',
    components: { LookupList },
    props: ['label', 'name', 'value', 'reftable', 'refcol'],
    data: () => ({
        openLookupList: false,
    }),
    computed: {
        lookupValue() {
            if(this.refcol) {
                return this.refcol.name
            } else {
                return ''
            }
        } 
    },
    methods: {
        openSelect() {
            //console.log('openSelect')
            this.openLookupList = true
        },
        onCancel() {
            this.openLookupList = false
        },
        onClear() {
            this.$emit('changed', { id: null })
        },
        onSubmit(e) {
            //console.log('LookUpList:', e)
            this.$emit('changed', e)
            this.openLookupList = false
        },
    },
}
</script>
<style scoped>
.lookup_cursor >>> input {
  cursor: pointer;
}
</style>