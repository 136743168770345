<template>
    <v-dialog ref="dialog" v-model="dialog" persistent width="290px">
        <template v-slot:activator="{ on, attrs }">
            <v-text-field class="centered-input"
            hide-details dense outlined clearable readonly clear-icon="mdi-close-circle-outline"
            :label="label"
            :ref="name"
            :placeholder="label"
            autocomplete="new-password"
            :value="newValue"
            v-bind="attrs"
            v-on="on"
            @click:clear="onClear($event)"
            @click="onClick($event)"
            ></v-text-field>
        </template>
        <v-date-picker :first-day-of-week="1" locale="de-de" scrollable show-adjacent-months show-week
            :value="dateValue" @input="onSubmit($event)"
        >
            <v-btn text color="warning" @click="onCancel()">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn text color="grey" disabled>{{ oldValue }}</v-btn>
        </v-date-picker>
    </v-dialog>
</template>
<script>
export default {
    name: 'DateInput',
    props: ['label', 'format', 'name', 'value'],
    data: () => ({
        dialog: false,
    }),
    computed: {
        dateValue() {
            return this.getDateValue(this.value)
        },
        newValue() {
            return this.formatDate(this.dateValue)
        },
        oldValue() {
            return this.getOldValue(this.newValue)
        },
    },
    mounted() {
    },
    methods: {
        formatDate (date) {
            if (!date || date.search("-") < 0) return null
            const [year, month, day] = date.split('-')
            const d = new Date(year, month - 1, day);
            return d.toLocaleDateString("de-DE", { year: "numeric", month: "2-digit", day: "2-digit", })
        },
        getDateValue(date) {
            if (!date || date.search("-") < 0) return null
            return date
        },
        getOldValue(val) {
            return this.label + ': ' + (val ? val : '...')
        },
        onCancel() {
            this.dialog = false
        },
        onClear() {
            this.$emit('changed', null)
        },
        onClick() {
            //this.dateValue = this.value
            //this.oldValue = this.label + ': ' + (this.dateValue ? this.formatDate(this.dateValue) : '...')
        },
        onSubmit(e) {
            this.$emit('changed', e)
            this.dialog = false
        },
        /*
        allowedDates: val => parseInt(val.split('-')[2], 10) % 2 === 0
        val >= new Date().toISOString().substr(0, 10)
        val => el.indexOf(val) !== -1
        allowedDates: a => val => ![a].includes(new Date(val).getDay())
        allowedDates(a) { return val => ![a].includes(new Date(val).getDay()); }
        allowedDates: function(a){ return val => ![a].includes(new Date(val).getDay()); }
        */
    },
}
</script>
<style scoped>
.centered-input >>> input {
    text-align: center
}
</style>