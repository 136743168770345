<template>
    <v-dialog v-model="dialog" scrollable max-width="600px" persistent overlay-opacity="0.75">
        <v-card tile class="grey darken-3">
            <v-app-bar dense color="blue-grey darken-2">
                <v-toolbar-title>{{ label }} Auswahl</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-text-field flat solo dense hide-details label="Suche" prepend-inner-icon="mdi-magnify" background-color="blue-grey darken-3"
                    v-model="search"
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-btn icon @click="onRefreshData">
                    <v-icon>mdi-reload</v-icon>
                </v-btn>
            </v-app-bar>
            <v-card-text style="height: 300px;">
                <v-list nav dense class="grey darken-3">
                    <v-list-item-group v-model="selectedItem" color="blue-grey lighten-3">
                    <v-list-item v-for="(item) in filteredItems" :key="item.id" :value="item.id">
                        <v-list-item-icon>
                        <v-icon v-text="item.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                        <v-list-item-title v-text="item.name"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-card-text>
            <v-divider />
            <v-card-actions>
            <v-btn text class="warning" @click="onCancel()">Abbrechen</v-btn>
            <v-spacer />
            <v-btn v-if="selectedItem" text class="success" @click="onSubmit()">Übernehmen</v-btn>
            </v-card-actions>
        </v-card>
		<v-dialog v-model="showmessage">
			<v-toolbar dark color="warning" dense>
				<v-toolbar-title class="white--text">{{ message.title }}</v-toolbar-title>
			</v-toolbar>
			<v-card tile>
				<v-card-text class="red--text" v-html="message.msg"/>
				<v-card-actions>
					<v-spacer/>
					<v-btn color="blue" text @click="onMessageClose()">Ok</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
        <v-overlay absolute :value="loading">
        	<v-progress-circular indeterminate size="64" width="7" color="cyan darken-4"></v-progress-circular>
        </v-overlay>
    </v-dialog>
</template>

<script>
export default {
    name: 'LookUpList',
    components: {},
    props: {
        label: { type: String, default: null, required: true, },
        reftable: { type: String, default: null, required: true, },
    },
    data: () => ({
        loading: false,
        dialog: false,
        search: null,
        items: [],
        selectedItem: null,
		message: {},
		showmessage: false,
    }),
    computed: {
        filteredItems() {
            if(this.items) {
                return this.items.filter(item => {
                    if(!this.search) return this.items
                    return (item.name.toLowerCase().includes(this.search.toLowerCase()))
                })
            } else {return []}
        },
    },
    methods: {
        async getData() {
            this.loading = true
			const param = {
				tablename: this.reftable,
			}
            try {
                await this.$store.dispatch('generic/getRefItems', param)
                this.items = this.$store.getters['generic/getRefItems']( param )
            } catch (error) {
                console.error(error)
                this.message.title = error.name
                this.message.msg = '<code>' + JSON.stringify(error.message).substring(0, 200) + '</code>'
                this.showmessage = true
            } finally {
                this.loading = false
            }
        },
        onRefreshData() {
            this.items = []
            this.getData()
        },
        onCancel() {
            this.$emit('cancel')
            this.dialog = false
        },
        onSubmit() {
            //console.log('selected:', this.selectedItem)
            this.$emit('submit', this.items.find(x => x.id == this.selectedItem))
            this.dialog = false
        },
		onMessageClose() {
			this.message = {}
			this.showmessage = false			
		},
    },
    mounted() {
        //console.log('dialog mounted')
        const param = {
            tablename: this.reftable,
        }
        this.items = this.$store.getters['generic/getRefItems']( param )
        if(!this.items) {
            this.getData()
        }
        this.dialog = true
    },
    beforeDestroy() {
        //console.log('destroying dialog')
    },
    watch: {

    },
}
</script>
<style scoped>
</style>